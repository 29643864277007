import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Space } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { postRequest } from "../../axios";

const { Option } = Select;

const ChangeUserType = (props) => {
  const navigate = useNavigate();
  const setSpinner = useOutletContext();
  const [form] = Form.useForm();
  const [state, setState] = useState({
    user_type: null,
    user_type_code: 0,
  });
  const [showModel, setShowModel] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showUserTypeCode, setShowUserTypeCode] = useState(false);
  const [userTypeCodeList, setUserTypeCodeList] = useState([]);

  const userTypeList = [
    { id: 0, name: "Member" },
    { id: 1, name: "State" },
    { id: 2, name: "District" },
    { id: 3, name: "Sub-Division" }
  ];

  const getProfileDetails = async () => {
    setSpinner(true);

    const apiResponse = await postRequest("user-details", {
      uid: props.memId
    });

    setSpinner(false);

    if (apiResponse.data.error === 0) {
      let res = apiResponse.data.response;

      setState({
        ...state,
        user_type: res.user_type,
        user_type_code: res.user_type_code
      })
      form.setFieldsValue({
        user_type: res.user_type,
        user_type_code: res.user_type_code
      });

      if (res.user_type === 2 || res.user_type === 3) {
        setShowUserTypeCode(true);
        getUtilityArrays(res.user_type);
      }
    }
  }

  const handleUserTypeChange = (field, value) => {
    setState({ ...state, [field]: value, user_type_code: 0 });
    form.setFieldsValue({ field: value, user_type_code: null });

    if (value === 2 || value === 3) {
      setShowUserTypeCode(true);
      getUtilityArrays(value);
    } else {
      setShowUserTypeCode(false);
    }
  }

  const getUtilityArrays = async (type) => {
    setSpinner(true);

    const apiResponse = await postRequest("get-utility-arrays");
    if (type === 2) {
      setUserTypeCodeList(apiResponse.data.response.district);
    }
    if (type === 3) {
      setUserTypeCodeList(apiResponse.data.response.subDivision);
    }

    setSpinner(false);
  }

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    getProfileDetails();
    setShowModel(true);
  };

  const onFinish = async () => {
    setBtnLoading(true);

    const payload = {
      uid: props.memId,
      user_type: state.user_type,
      user_type_code: state.user_type_code
    };

    try {
      const apiResponse = await postRequest("change-user-type", payload);

      if (apiResponse.data.error === 0) {
        SuccessNotificationMsg("Success", "Successfully updated");
        navigate("/members");
        props.showList(false);
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }

    setShowModel(false);
  };

  return (
    <>
      <button
        className="btn btn-xs btn-outline-success"
        onClick={() => showModelFunction()}
      >
        CHANGE
      </button>

      <Modal
        title="Change User Type"
        visible={showModel}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[15]} className="mb-3">
            <Col xs={24} sm={8} lg={8}>
              <Form.Item
                name="user_type"
                label="Select User Type"
                rules={[
                  {
                    required: true,
                    message: "Please select user type!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select User Type"
                  className="form-control"
                  onChange={(value) =>
                    handleUserTypeChange("user_type", value)
                  }
                >
                  {!!userTypeList &&
                    userTypeList.map((utl) => (
                      <Option key={utl.id} value={utl.id}>
                        {utl.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            {showUserTypeCode &&
              <Col xs={24} sm={8} lg={8}>
                <Form.Item
                  name="user_type_code"
                  label="Select District / Sub-Division"
                  rules={[
                    {
                      required: true,
                      message: "Please select district / sub-division!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select District / Sub-Division"
                    className="form-control"
                    onChange={(value) =>
                      handleSelectChange("user_type_code", value)
                    }
                  >
                    {!!userTypeCodeList &&
                      userTypeCodeList.map((dist) => (
                        <Option key={dist.id} value={dist.id}>
                          {dist.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            }
          </Row>

          <div className="panel-content mt-2 d-flex flex-row justify-content-end">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                className="btn btn-primary ml-auto waves-effect waves-themed"
              >
                Submit
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeUserType;