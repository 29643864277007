import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Space, Button } from "antd";

import PageHeader from "../common/PageHeader";
import { getUserData, getUserType } from "../../utils/Helpers";
import MemberList from "./MemberList";
import { postRequest } from "../../axios";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const Members = () => {
  const setSpinner = useOutletContext();
  const [state, setState] = useState({
    dist_code: getUserData().user_type_code,
    sub_code: null
  });
  const [form] = Form.useForm();
  const [showList, setShowList] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);

  useEffect(() => {
    if (getUserType() === "1") {
      setSpinner(true);

      const getUtilityArrays = async () => {
        const apiResponse = await postRequest("get-utility-arrays");
        setDistrictList(apiResponse.data.response.district);

        setSpinner(false);
      }
      getUtilityArrays();
    } else if (getUserType() === "2") {
      setState({ ...state, dist_code: getUserData().user_type_code });

      getSubDivisionList(getUserData().user_type_code);
    } else {
      setState({
        ...state,
        dist_code: getUserData().user_type_code.substr(0, 4),
        sub_code: getUserData().user_type_code
      });
      setShowList(true);
    }
  }, [setSpinner]);

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });

    setShowList(false);
  };

  const handleSchoolDistrictChange = async (field, value) => {
    setState({ ...state, [field]: value, sub_code: null });
    form.setFieldsValue({ field: value, sub_code: null });

    getSubDivisionList(value);
    setShowList(false);
  };

  const getSubDivisionList = async (distCode) => {
    setShowList(false);
    setSpinner(true);

    const subDivnList = await postRequest("get-sub-division-by-district", {
      district: distCode,
    });

    setSubDivisionList(subDivnList.data.response);
    setSpinner(false);
  }

  return (
    <div id="content">
      <PageHeader
        pageTitle="Members"
        pageIcon={<i className="subheader-icon fal fa-users"></i>}
      />
      {getUserType() === "3" ? (
        <>
          {showList && (
            <MemberList
              dist_code={state.dist_code}
              sub_code={state.sub_code}
              showList={setShowList}
            />
          )}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div id="panel-1" className="panel">
                <div className="panel-hdr">
                  <h2>Member List</h2>
                </div>
                <div className="panel-container show">
                  <div className="panel-content">
                    {getUserType() === "1" ? (
                      <Form
                        form={form}
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Row gutter={[15]} className="mb-3">
                          <Col xs={24} sm={8} lg={8}>
                            <Form.Item
                              name="dist_code"
                              label="Select District"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select district!",
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Select District"
                                className="form-control"
                                onChange={(value) =>
                                  handleSchoolDistrictChange("dist_code", value)
                                }
                              >
                                {!!districtList &&
                                  districtList.map((dist) => (
                                    <Option key={dist.id} value={dist.id}>
                                      {dist.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={8} lg={8}>
                            <Form.Item
                              label="Select Sub-Division"
                              name="sub_code"
                            >
                              <Select
                                allowClear
                                placeholder="Select sub-division"
                                onChange={(value) =>
                                  handleSelectChange("sub_code", value)
                                }
                              >
                                {!!subDivisionList &&
                                  subDivisionList.map((sdnl) => (
                                    <Option key={sdnl.id} value={sdnl.id}>
                                      {sdnl.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <Form
                        form={form}
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Row gutter={[15]} className="mb-3">
                          <Col xs={24} sm={8} lg={8}>
                            <Form.Item
                              label="Select Sub-Division"
                              name="sub_code"
                            >
                              <Select
                                allowClear
                                placeholder="Select sub-division"
                                onChange={(value) =>
                                  handleSelectChange("sub_code", value)
                                }
                              >
                                {!!subDivisionList &&
                                  subDivisionList.map((sdnl) => (
                                    <Option key={sdnl.id} value={sdnl.id}>
                                      {sdnl.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                      <Space>
                        <Button
                          type="primary"
                          onClick={() =>
                            setShowList(true)
                          }
                          className="btn btn-primary ml-auto waves-effect waves-themed"
                        >
                          Show List
                        </Button>
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showList && (
            <MemberList
              dist_code={state.dist_code}
              sub_code={state.sub_code}
              showList={setShowList}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Members;