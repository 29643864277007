import React, { useState, useEffect } from "react";
import { postRequest } from "../../axios";
import { Checkbox, Space } from 'antd';

import PageHeader from "../common/PageHeader";
import { Link, useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { getUserData } from "../../utils/Helpers";
import ProfileDetailsBody from "./ProfileDetailsBody";

const ProfileDetails = () => {
  const [profileDetails, setProfileDetails] = useState([]);
  const setSpinner = useOutletContext();
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const getProfileDetails = async () => {
      setSpinner(true);
      try {
        const apiResponse = await postRequest("user-details", {
          uid: getUserData().uid
        });

        if (apiResponse.data.error === 0) {
          setProfileDetails(apiResponse.data.response);
          setChecked(apiResponse.data.response.bulletin);
          setDisabled(!apiResponse.data.response.can_apply_bulletin);
        } else {
          ErrorNotificationMsg(apiResponse.data.errmsg);
        }
      } catch (error) {
        ErrorNotificationMsg(error.errmsg);
      }
      setSpinner(false);
    }
    getProfileDetails();
  }, [setSpinner]);

  const handleBulletinCheck = async () => {
    setChecked(!checked);

    const apiResponse = await postRequest("toggle-bulletin-subscription", {
      uid: getUserData().uid,
      check: !checked
    });

    if (apiResponse.data.error === 0) {
      SuccessNotificationMsg(apiResponse.data.response);
    } else {
      ErrorNotificationMsg(apiResponse.data.errmsg);
    }
  }

  return (
    <div id="content">
      <PageHeader
        pageTitle="My Profile"
        pageIcon={<i className="subheader-icon fal fa-user"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>My Profile</h2>
              <div className="panel-toolbar">
                <Checkbox checked={checked} disabled={disabled} onChange={handleBulletinCheck}>Subscribe for bulletin</Checkbox>
              </div>

              {profileDetails.can_edit === 1 ? (
                <div className="panel-toolbar">
                  <Space>
                    <Link
                      to="/profile-edit"
                      className="btn btn-sm btn-success waves-effect waves-themed"
                    >
                      <i className="fal fa-edit"></i> Edit Profile
                    </Link>
                  </Space>
                </div>
              ) : ""}
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <ProfileDetailsBody profileDetails={profileDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;