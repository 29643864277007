import React, { useState, useRef } from "react";
import { postRequest } from "../../axios";
import { Modal } from "antd";

const PaymentGateway = (props) => {
  const formRef = useRef();
  const [pgDetails, setPgDetails] = useState([]);
  const [showPGModel, setShowPGModel] = useState(false);

  const hidePGModelFunction = () => {
    setShowPGModel(false);
  };

  const showPGModelFunction = () => {
    setShowPGModel(true);
  };

  const getPgDetails = async () => {
    const apiResponse = await postRequest("get-pg-details", {
      amount: props.dueAmt,
      uid: props.uid
    });
    setPgDetails(apiResponse.data.response);
    showPGModelFunction();
  }

  return (
    <>
      <button
        onClick={() => getPgDetails()}
        className="btn btn-block btn-success"
        type="submit"
      >
        PAY NOW ( <i className="fal fa-rupee-sign"></i> {props.dueAmt} )
      </button>

      <Modal
        title="Fees Payment"
        visible={showPGModel}
        onCancel={hidePGModelFunction}
        footer={false}
      >
        <p>
          Payable Amount : <i className="fal fa-rupee-sign"></i> <strong>{props.dueAmt}</strong>
        </p>
        <form ref={formRef} action="https://secure.payu.in/_payment" method="POST">
          <input type="hidden" name="key" value={pgDetails.merchantKey} />
          <input type="hidden" name="hash" value={pgDetails.hash} />
          <input type="hidden" name="txnid" value={pgDetails.txnid} />
          <input type="hidden" name="amount" value={props.dueAmt} />
          <input type="hidden" name="firstname" value={pgDetails.firstname} />
          <input type="hidden" name="email" value={pgDetails.email} />
          <input type="hidden" name="phone" value={pgDetails.phone} />
          <input type="hidden" name="productinfo" value={pgDetails.productinfo} />
          <input type="hidden" name="surl" value={pgDetails.surl} />
          <input type="hidden" name="furl" value={pgDetails.furl} />
          <input type="hidden" name="udf1" value={pgDetails.udf1} />
          <input
            type="submit"
            value={"Redirect to Payment Gateway Page"}
            className="btn btn-primary ml-auto waves-effect waves-themed"
          />
        </form>
      </Modal>
    </>
  );
};

export default PaymentGateway;