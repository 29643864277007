import React, { useState, useEffect } from "react";

import { postRequest } from "../../axios";
import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import moment from "moment";
import FeesPdfModal from "../memberFees/FeesPdfModal";

const ReceiptInformationShow = (props) => {
  const [information, setInformation] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getInformation();
  }, []);

  const getInformation = async () => {
    setSpinner(true);

    const apiResponse = await postRequest("get-fees-receipt-information", {
      dist_code: props.state.dist_code,
      date_from: moment(props.state.date_from, "DD-MM-YYYY").format("YYYY-MM-DD"),
      date_to: moment(props.state.date_to, "DD-MM-YYYY").format("YYYY-MM-DD"),
    });
    if (apiResponse.data.error === 0) {
      setInformation(apiResponse.data.response);
    } else {
      ErrorNotificationMsg(apiResponse.data.errmsg);
    }

    setSpinner(false);
  };

  var gTotal = 0;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Receipt List</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                {information.length > 0 ?
                  <table className="table table-sm table-bordered table-hover">
                    <thead className="thead-themed">
                      <tr className="text-center">
                        <th>SL. NO.</th>
                        <th>RECEIPT NO. / DATE / MODE</th>
                        <th>MEMBER</th>
                        <th>FEES DETAILS</th>
                        <th>TOTAL</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {information.map((fees, fid) => {
                        let feeList = fees.fees_details;
                        var total = 0;
                        return (
                          <tr>
                            <td>{fid + 1}</td>
                            <td>
                              <span className="d-block">No. - {fees.receipt_no}</span>
                              <span className="d-block">Date - {fees.receipt_date}</span>
                              <span className="d-block">Mode - {fees.paid_mode}</span>
                              <span className="d-block">{fees.paid_remarks !== '' ? fees.paid_remarks : ""}</span>
                            </td>
                            <td>
                              <span className="d-block"><strong>{fees.user_name}</strong></span>
                              <span className="d-block">{fees.school_district}</span>
                            </td>
                            <td>
                              {feeList.map((amt, mid) => {
                                let fd = amt.feesAmount;
                                return (
                                  <>
                                    {
                                      fd.map((fee, pid) => {
                                        total = total + parseInt(fee.amount);
                                        gTotal = gTotal + parseInt(fee.amount);
                                        return (
                                          <>
                                            <div className="row mt-1 ml-1">
                                              {fd.length > 1 && pid === 0 &&
                                                <span>Year :{" "}<strong>{amt.finYear}</strong></span>
                                              }
                                              {fd.length === 1 &&
                                                <span>Year :{" "}<strong>{amt.finYear}</strong></span>
                                              }
                                            </div>
                                            <div className="row ml-2" key={"p" + fid + mid + pid}>
                                              <div class="col">{fee.feesName}</div>
                                              <div class="col text-right">
                                                <i className="fal fa-rupee-sign"></i>
                                                <span>{" "}{fee.amount}</span>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })
                                    }
                                  </>
                                );
                              })}
                            </td>
                            <td className="text-right">
                              <i className="fal fa-rupee-sign"></i>
                              <span>{" "}{total}</span>
                            </td>
                            <td className="text-center">
                              <FeesPdfModal
                                receiptId={fees.id}
                                copyType={0}
                                buttonText="Re-Print"
                                buttonClassName="btn btn-danger btn-sm"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={4} className="text-right">G. TOTAL -</th>
                        <td className="text-right">
                          <i className="fal fa-rupee-sign"></i>{" "}
                          <strong>{gTotal}</strong>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                  :
                  <div className="alert alert-info fade show px-3 py-2" role="alert">
                    No record(s) found
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptInformationShow;