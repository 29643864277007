import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select } from "antd";

import PageHeader from "../common/PageHeader";
import { postRequest } from "../../axios";
import UpdateLastPaid from "./UpdateLastPaid";

const { Option } = Select;

const PreviousData = () => {
  const [state, setState] = useState({
    previous_data_id: null
  });
  const [previousDataList, setPreviousDataList] = useState([]);
  const [previousDataDetails, setPreviousDataDetails] = useState(null);

  useEffect(() => {
    getPreDataList();
  }, []);

  const getPreDataList = async () => {
    const apiResponse = await postRequest("get-previous-data-name-list");
    setPreviousDataList(apiResponse.data.response.list);
  }

  const handlePreDataSelectChange = (field, value) => {
    setState({ ...state, [field]: value });

    getPreDataDetails(value);
  };

  const getPreDataDetails = async (id) => {
    const apiResponse = await postRequest("get-previous-data-details", {
      id: id
    });
    setPreviousDataDetails(apiResponse.data.response);
  }

  return (
    <div id="content">
      <PageHeader
        pageTitle="Members"
        pageIcon={<i className="subheader-icon fal fa-users"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Previous Data</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]}>
                    <Col xs={24} sm={16} lg={16}>
                      <Form.Item
                        label="Choose Member Name"
                        name="previous_data_id"
                        rules={[
                          {
                            required: true,
                            message: "Please choose member name!",
                          },
                        ]}
                      >
                        <Select
                          showSearch={true}
                          placeholder="Choose Member Name"
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          onChange={(value) =>
                            handlePreDataSelectChange("previous_data_id", value)
                          }
                        >
                          {!!previousDataList &&
                            previousDataList.map((pdl) => (
                              <Option key={pdl.id} value={pdl.id}>
                                {pdl.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={24} lg={24}>
                      {previousDataDetails !== null ? (
                        <>
                          <span className="d-block">
                            Last paid upto : <strong>{previousDataDetails.lastPaid}</strong>
                            <UpdateLastPaid
                              previousData={previousDataDetails}
                              getPreDataDetails={getPreDataDetails}
                            />
                          </span>
                          <span className="d-block">Subscribed for : <strong>{previousDataDetails.subType}</strong></span>
                          <span className="d-block">School : <strong>{previousDataDetails.schoolName}</strong></span>
                        </>
                      ) : ""}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousData;