import React, { useState, useEffect } from "react";
import { postRequest } from "../../axios";

import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import PaymentGateway from "./PaymentGateway";

const FeesDue = (props) => {
  const setSpinner = useOutletContext();
  const [feesDueList, setFeesDueList] = useState([]);

  var totalDue = 0;

  useEffect(() => {
    const getFeesDueList = async () => {
      setSpinner(true);

      try {
        const apiResponse = await postRequest("get-fees-due-user", {
          uid: props.uid
        });

        if (apiResponse.data.error === 0) {
          setFeesDueList(apiResponse.data.response);
        }
      } catch (error) {
        ErrorNotificationMsg(error.errmsg);
      }
      setSpinner(false);
    }
    getFeesDueList();
  }, [setSpinner, props.uid]);

  return (
    <div id="panel-1" className="panel">
      <div className="panel-hdr">
        <h2>Due Fees Details</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content">
          {feesDueList.length > 0 ?
            <table className="table table-sm table-bordered table-hover">
              <thead className="thead-themed text-center">
                <tr>
                  <th>SL</th>
                  <th>YEAR</th>
                  <th>DISCRIPTION</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {feesDueList.map((fees, fid) => {
                  let amtList = fees.feesAmount;
                  return (
                    <>
                      {amtList.map((amt, mid) => {
                        totalDue = totalDue + parseInt(amt.amount);
                        return (
                          <tr key={"f" + fid + mid}>
                            {amtList.length > 1 && mid === 0 &&
                              <>
                                <td rowSpan={amtList.length} className="text-right">{fid + 1}</td>
                                <td rowSpan={amtList.length} className="text-center">{fees.finYear}</td>
                              </>
                            }
                            {amtList.length === 1 &&
                              <>
                                <td className="text-right">{fid + 1}</td>
                                <td className="text-center">{fees.finYear}</td>
                              </>
                            }
                            <td>{amt.feesName}</td>
                            <td className="text-right">
                              <i className="fal fa-rupee-sign"></i>
                              <span>{" "}{amt.amount}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })
                }
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={3} className="text-right">Total -</th>
                  <td className="text-right">
                    <i className="fal fa-rupee-sign"></i>
                    <span><strong>{" "}{totalDue}</strong></span>
                  </td>
                </tr>

                <tr>
                  <th colSpan={4}>
                    <PaymentGateway
                      dueAmt={totalDue}
                      uid={props.uid}
                    />
                  </th>
                </tr>

              </tfoot>
            </table>
            :
            <div className="alert alert-info fade show px-3 py-2" role="alert">
              No fees due
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FeesDue;