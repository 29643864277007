import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Input, Button, Form } from "antd";
import { postRequest } from "../../axios";

import {
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

const ForgotPassword = (props) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    mobile_number: null,
    type: "forget-password",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const onSubmit = async () => {
    setBtnLoading(true);

    try {
      const apiResponse = await postRequest("otp-send", state);
      if (apiResponse.data && apiResponse.data.error === 0) {
        navigate(
          "/otp-verify",
          {
            state: {
              mobileNumber: state.mobile_number,
              type: "forget-password"
            }
          }
        );
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  };

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-key"></i> Forget Password
                </h1>
              </div>

              <Form onFinish={onSubmit} autoComplete="off" layout="vertical">
                <Form.Item
                  label="Registered Mobile Number"
                  name="mobile_number"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[0-9+]{0,10}$"),
                      message: "Please enter registered mobile number",
                    },
                  ]}
                >
                  <Input
                    onChange={(value) => handleChange("mobile_number", value)}
                    placeholder="Enter registered mobile number"
                    maxLength="10"
                  />
                </Form.Item>

                <div class="row no-gutters">
                  <div class="col-md-12 ml-auto text-right">
                    <Button className="mt-3" type="primary" htmlType="submit" loading={btnLoading}>
                      Submit
                    </Button>
                    <Button
                      className="ml-2 mt-3"
                      type="secondary"
                      htmlType="button"
                      onClick={() => navigate("/login")}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
