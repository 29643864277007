import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import LogoFull from "../../styles/LogoFull";
import { validateToken } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import "antd/dist/antd.min.css";
import "../../css/page-login-alt.css";

const FrontLayout = () => {
  const [state, setState] = useState({
    loginStatus: false,
    visible: false,
  })

  useEffect(() => {
    const loginStatus = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY)
    );
    setState({ loginStatus });
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-inner bg-brand-gradient">
        <div className="page-content-wrapper bg-transparent m-0">
          <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div className="text-center container p-0 width-mobile-auto">
                <LogoFull />
            </div>
          </div>

          <Outlet />

        </div>
      </div>
    </div>
  );
}

export default FrontLayout;
