import { Upload } from "antd";
import React, { useEffect, useState } from "react";

import userIcon from "../../images/userIcon.jpg";
import ImgCrop from "antd-img-crop";
import imageCompression from 'browser-image-compression';
import { isImageOrFile } from "../../utils/Helpers";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { postRequest } from "../../axios";

const ProfileDetailsBody = (props) => {
  const profileDetails = props.profileDetails;

  const [state, setState] = useState({
    photo_file: {
      fileName: "",
      ext: "",
      file: "",
      url: userIcon
    },
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setState({
      ...state, photo_file: {
        fileName: profileDetails.photo_file_name,
        ext: profileDetails.photo_file_ext,
        file: profileDetails.photo_file_base64,
        url: profileDetails.photo_file_url
      }
    });
  }, [profileDetails]);

  const beforeCropFeature = async (file) => {
    if (isImageOrFile(file.type)) {
      const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };
      const compressedFile = await imageCompression(file, options)
      if (compressedFile.size > 1048576) {
        ErrorNotificationMsg("Maximum size for file upload is 1MB.");
        return false;
      }
      return true;
    } else {
      ErrorNotificationMsg("Supported file types are jpg, jpeg");
      return false;
    }
  };

  const customRequest = async ({ file: file }) => {
    let photoObj = {
      fileName: file.name,
      ext: "." + file.name.split(".").pop(),
    };

    getBase64(file, (imageUrl) => (photoObj.url = imageUrl));
    getBase64(file, (imageUrl) => (photoObj.file = imageUrl.replace("data:", "").replace(/^.+,/, "")));
    await sleep(300);

    setState({ ...state, photo_file: photoObj });

    try {
      const apiResponse = await postRequest("user-edit-photo", {
        photo_file: photoObj
      });

      if (apiResponse.data.error === 0) {
        SuccessNotificationMsg("Success", "Profile photo updated successfully.");
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handlePhotoRemove = async () => {
    setState({
      ...state,
      photo_file: {
        fileName: "",
        ext: "",
        file: "",
        url: userIcon,
      }
    });
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-2 col-lg-2">
        <div className="card border m-auto m-lg-0">
          <img
            src={state.photo_file?.url}
            className="img-thumbnail"
            alt="Profile Photo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = userIcon;
            }}
          />
        </div>
        <ImgCrop
          rotate={true}
          aspect={1 / 1}
          beforeCrop={beforeCropFeature}
        >
          <Upload
            customRequest={customRequest}
            onRemove={handlePhotoRemove}
            maxCount={1}
          >
            <button className="btn btn-xs btn-success mt-2"><i className="fal fa-image"> CHANGE PHOTO</i></button>
          </Upload>
        </ImgCrop>
      </div>

      <div className="col-sm-12 col-md-5 col-lg-5">
        <table className="table table-sm table-bordered table-hover table-striped w-100">
          <thead className="thead-themed">
            <tr><th colSpan={2} className="text-center">PERSONAL DETAILS</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td><td><strong>{profileDetails?.user_name}</strong></td>
            </tr>
            <tr>
              <td>Designation</td><td>{profileDetails?.designation_text}</td>
            </tr>
            <tr>
              <td>Date of Birth</td><td>{profileDetails?.birth_date}</td>
            </tr>
            <tr>
              <td>Gender</td><td>{profileDetails?.gender_text}</td>
            </tr>
            <tr>
              <td>Religion</td><td>{profileDetails?.religion_text}</td>
            </tr>
            <tr>
              <td>Father / Husband</td><td>{profileDetails?.father_husband}</td>
            </tr>
            <tr>
              <td>Present School</td><td><strong>{profileDetails?.school_name}</strong></td>
            </tr>
            <tr>
              <td>School District</td><td>{profileDetails?.school_district_text}</td>
            </tr>
            <tr>
              <td>School Sub-Division</td><td>{profileDetails?.school_sub_division_text}</td>
            </tr>
            <tr>
              <td>Service Status</td><td><strong>{profileDetails?.service_status_text}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-sm-12 col-md-5 col-lg-5">
        <table className="table table-sm table-bordered table-hover table-striped w-100">
          <thead className="thead-themed">
            <tr><th colSpan={2} className="text-center">CONTACT DETAILS</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>Address</td><td>{profileDetails?.postal_address}</td>
            </tr>
            <tr>
              <td>District</td><td>{profileDetails?.district_code_text}</td>
            </tr>
            <tr>
              <td>PIN</td><td>{profileDetails?.pin_code}</td>
            </tr>
            <tr>
              <td>Mobile No.</td><td><strong>{profileDetails?.mobile_number}</strong></td>
            </tr>
            <tr>
              <td>Whatsapp No.</td><td>{profileDetails?.whatsapp_number}</td>
            </tr>
            <tr>
              <td>E-mail</td><td>{profileDetails?.email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfileDetailsBody;