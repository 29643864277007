import React from "react";
import { Routes, Route } from "react-router-dom";

import config from "../../Config";

import PrivateRoute from "./PrivateRoute";

/* open Route Components */
import Login from "../authentication/Login";
import Register from "../authentication/Register";
import RegisterInstruction from "../authentication/RegisterInstruction";
import OtpVerify from "../authentication/OtpVerify";
import ForgotPassword from "../authentication/ForgotPassword";
import ForgotPasswordVerification from "../authentication/ForgotPasswordVerification";

/* Auth Required Private Route Components */
import Dashboard from "../dashboard/Dashboard";
import ProfileDetails from "../profile/ProfileDetails";
import ProfileNew from "../profile/ProfileNew";

import Members from "../members";
import Receipt from "../associationFees/Receipt";

import Fees from "../memberFees";
import PaymentGateway from "../memberFees/PaymentGateway";
import PaymentSuccess from "../memberFees/PaymentSuccess";
import PaymentFailed from "../memberFees/PaymentFailed";

// import NoticeBoard from "../noticeBoard/NoticeBoard";
// import NoticeBoardComment from "../noticeBoard/NoticeBoardComment";

/* Other Common Route Components */
import Logout from "../authentication/Logout";
import Maintenance from "./Maintenance";
import NotFound from "./NotFound";
import NoPermission from "./NoPermission";
import AdminLayout from "./AdminLayout";
import FrontLayout from "./FrontLayout";
import ErrorLayout from "./ErrorLayout";
import ReceiptInformation from "../associationFees/ReceiptInformation";
import ProfileEdit from "../profile/ProfileEdit";
import PreviousData from "../members/PreviousData";

function MainRoutes() {
  if (config.IS_MAINTENANCE_MODE) {
    return (
      <Routes>
        <Route path="*" element={<Maintenance />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<FrontLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register-instruction" element={<RegisterInstruction />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password-verification" element={<ForgotPasswordVerification />} />
        <Route path="/otp-verify" element={<OtpVerify />} />
        <Route path="/profile-new" element={<ProfileNew />} />
      </Route>

      <Route element={<AdminLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          
          <Route path="/members" element={<Members />} />
          <Route path="/previous-data" element={<PreviousData />} />

          <Route path="/receipt" element={<Receipt />} />
          <Route path="/receipt-information" element={<ReceiptInformation />} />
          
          <Route path="/profile-details" element={<ProfileDetails />} />
          <Route path="/profile-edit" element={<ProfileEdit />} />
          
          <Route path="/member-fees" element={<Fees />} />

          <Route path="/payment-gateway" element={<PaymentGateway />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failed" element={<PaymentFailed />} />

          <Route path="/no-permission" element={<NoPermission />} />
        </Route>
      </Route>

      <Route element={<ErrorLayout />}>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;