import React, { useState } from "react";
import { Col, Modal, Row } from "antd";
import FeesDue from "../memberFees/FeesDue";
import FeesPaid from "../memberFees/FeesPaid";

const MemberFees = (props) => {
  const [showModel, setShowModel] = useState(false);

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
  };

  return (
    <>
      <button
        className="btn btn-xs btn-danger ml-2"
        onClick={() => showModelFunction()}
      >
        FEES
      </button>

      <Modal
        title="Fees Details"
        visible={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Row gutter={[15]}>
          <Col xs={24} sm={12} lg={12}>
            <FeesDue uid={props.memId} />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <FeesPaid uid={props.memId} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MemberFees;