import React, { useState, useEffect } from "react";

import { postRequest } from "../../axios";
import { useOutletContext } from "react-router-dom";
import MemberDetails from "./MemberDetails";
import MemberFees from "./MemberFees";
import { getUserType } from "../../utils/Helpers";
import ChangeUserType from "./ChangeUserType";
import { Button, Table } from "antd";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";

const MemberList = (props) => {
  const [members, setMembers] = useState([]);
  const setSpinner = useOutletContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    setSpinner(true);
    const response = await postRequest("get-member-list", {
      dist_code: props.dist_code,
      sub_code: props.sub_code,
    });
    setMembers(response.data.response.memberList);
    setSpinner(false);
  };

  const data = [];
  const nameFilter = [];
  const mobileFilter = [];
  const districtFilter = [];
  if (members) {
    members.map((member, id) => {
      data.push({
        key: member.id,
        slno: id + 1,
        name: member,
        designation: member.designation,
        district: member.district,
        mobile: member.mobile,
        userType: member,
        action: member
      });

      nameFilter.push({
        text: member.name,
        value: member.name,
      })

      mobileFilter.push({
        text: member.mobile,
        value: member.mobile,
      })

      districtFilter.push({
        text: member.district,
        value: member.district,
      })
    });
  }

  const newDistrictFilter = districtFilter.filter((val, index, self) =>
    index === self.findIndex((t) => (
      t.text === val.text && t.value === val.value
    ))
  )

  const columns = [
    {
      title: "SL. NO.",
      dataIndex: 'slno',
    },
    {
      title: "NAME",
      dataIndex: 'name',
      filters: nameFilter,
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
      render: (_, mem) => (
        <>
          {mem.name.canEdit ? <i className="fal fa-user-edit text-success"></i> : ""}
          {"  " + mem.name.name}
        </>
      )
    },
    {
      title: "DESIGNATION",
      dataIndex: 'designation',
    },
    {
      title: "DISTRICT",
      dataIndex: 'district',
      filters: newDistrictFilter,
      filterSearch: true,
      onFilter: (value, record) => record.district.includes(value),
    },
    {
      title: "MOBILE",
      dataIndex: 'mobile',
      filters: mobileFilter,
      filterSearch: true,
      onFilter: (value, record) => record.mobile.includes(value),
    },
    {
      title: "USER TYPE",
      dataIndex: 'userType',
      render: (_, mem) => (
        getUserType() === "1" && (
          <>
            <ChangeUserType
              memId={mem.userType.id}
              showList={props.showList}
            />
            <span className={
              mem.userType.type === 1 ?
                "badge badge-pill badge-danger ml-2"
                : mem.userType.type === 2 ?
                  "badge badge-pill badge-success ml-2"
                  : "badge badge-pill badge-warning ml-2"
            }>
              {mem.userType.typeName}
            </span>
            <br />
            {mem.userType.typeCodeName !== '' && mem.userType.typeCodeName}
          </>
        )
      )
    },
    {
      title: "ACTION",
      dataIndex: 'action',
      render: (_, mem) => (
        <>
          <MemberDetails
            memId={mem.action.id}
          />
          <MemberFees
            memId={mem.action.id}
          />
        </>
      )
    },
  ];

  const start = () => {
    setBtnLoading(true);

    const updateStatus = async () => {
      try {
        const apiResponse = await postRequest("user-change-edit-status", {
          uids: selectedRowKeys
        });

        if (apiResponse.data.error === 0) {
          SuccessNotificationMsg("Success", "Updated successfully.");

          getMembers();
        } else {
          ErrorNotificationMsg(apiResponse.data.errmsg);
        }
      } catch (error) {
        ErrorNotificationMsg(error.errmsg);
      }
    }

    updateStatus();

    setTimeout(() => {
      setSelectedRowKeys([]);
      setBtnLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Member List</h2>
              <div className="panel-toolbar">
                <Button type="primary" onClick={start} disabled={!hasSelected} loading={btnLoading}>
                  Allow Edit
                </Button>
                <span
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                {members.length > 0 ?
                  <div className="table-responsive">
                    <Table
                      bordered
                      size="small"
                      rowSelection={rowSelection}
                      columns={columns}
                      dataSource={data}
                    />
                  </div>
                  :
                  <div className="alert alert-info fade show px-3 py-2" role="alert">
                    No member list found
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberList;