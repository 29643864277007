import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Form } from "antd";
import { postRequest } from "../../axios";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { redirectIfLoggedIn } from "../../utils/Helpers";

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    user_name: null,
    mobile_number: null,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value.toUpperCase() });
    form.setFieldsValue({ field: value.target.value.toUpperCase() })
  };

  const onRegister = async () => {
    setBtnLoading(true);

    try {
      const apiResponse = await postRequest("register", state);

      if (apiResponse.data && apiResponse.data.error === 0) {
        if (apiResponse.data.response.step_completed > 0) {
          SuccessNotificationMsg(
            "Success",
            "Already registered! Please Sign-In"
          );
          setBtnLoading(false);

          navigate("/login");
        } else {
          SuccessNotificationMsg(
            "Success",
            "OTP successfully sent for verification!"
          );
          setBtnLoading(false);

          navigate(
            "/otp-verify",
            {
              state: {
                user_name: state.user_name,
                mobileNumber: apiResponse.data.response.mobile_number,
                type: "register"
              }
            }
          );
        }
      } else {
        ErrorNotificationMsg(apiResponse.data.response.errmsg);
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  };

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-sign-in"></i> Register
                </h1>
              </div>
              <Form onFinish={onRegister} form={form} autoComplete="off" layout="vertical">
                <Form.Item
                  label="Enter Your Name"
                  name="user_name"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[a-zA-Z\\.\\s]*$"),
                      message: "Please enter your name",
                    },
                  ]}
                >
                  <Input
                    onChange={(value) => handleChange("user_name", value)}
                    placeholder="Enter your name"
                    maxLength="50"
                  />
                </Form.Item>

                <Form.Item
                  label="Mobile Number"
                  name="mobile_number"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[0-9+]{0,10}$"),
                      message: "Please enter your mobile number",
                    },
                  ]}
                >
                  <Input
                    onChange={(value) => handleChange("mobile_number", value)}
                    placeholder="Enter your mobile number"
                    maxLength="10"
                  />
                </Form.Item>

                <div class="row no-gutters">
                  <div class="col-md-12 ml-auto text-right">
                    <Button className="mt-3" type="primary" htmlType="submit" loading={btnLoading}>
                      Register
                    </Button>
                  </div>
                </div>
              </Form>

              <div className="border-top mt-4">
                <div className="text-center mt-3">
                  Already Registered?&nbsp;
                  <Link to="/login" className="btn btn-xs btn-danger">
                    <strong>Sign-In</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;