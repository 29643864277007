import React, { useState } from "react";
import { Modal } from "antd";
import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { postRequest } from "../../axios";
import ProfileDetailsBody from "../profile/ProfileDetailsBody";

const MemberDetails = (props) => {
  const [profileDetails, setProfileDetails] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const setSpinner = useOutletContext();

  const getProfileDetails = async () => {
    setSpinner(true);
    try {
      const apiResponse = await postRequest("user-details", {
        uid: props.memId
      });

      if (apiResponse.data.error === 0) {
        setProfileDetails(apiResponse.data.response);
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      ErrorNotificationMsg(error.errmsg);
    }
    setSpinner(false);
  }

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    getProfileDetails();
    setShowModel(true);
  };

  return (
    <>
      <button
        className="btn btn-xs btn-info"
        onClick={() => showModelFunction()}
      >
        DETAILS
      </button>

      <Modal
        title="Member Details"
        visible={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <ProfileDetailsBody profileDetails={profileDetails} />
      </Modal>
    </>
  );
};

export default MemberDetails;