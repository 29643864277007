import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Button,
  DatePicker,
  Typography,
  Upload
} from "antd";
import imageCompression from 'browser-image-compression';
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { postRequest } from "../../axios";
import ImgCrop from "antd-img-crop";
import { isImageOrFile } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";

const { Option } = Select;

const ProfileNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { Title } = Typography;

  const [state, setState] = useState({
    user_name: null,
    designation: [],
    birth_date: moment().format("DD-MM-YYYY"),
    gender: [],
    religion: [],
    father_husband: null,

    postal_address: null,
    district_code: [],
    pin_code: null,

    mobile_number: null,
    whatsapp_number: null,
    email_id: null,

    school_name: null,
    school_district: null,
    school_sub_division: null,

    photo_file: {
      fileName: "",
      ext: "",
      file: "",
      url: userIcon
    },

    service_status: [],
    member_type: null,
    previous_data_id: null
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const [designation, setDesignation] = useState([]);
  const [gender, setGender] = useState([]);
  const [religion, setReligion] = useState([]);
  const [districtCode, setDistrictCode] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [schoolName, setSchoolName] = useState([]);
  const serviceStatus = [
    { id: 0, name: 'In Service' },
    { id: 1, name: 'Retired' }
  ];
  const memberType = [
    { id: 0, name: 'YES' },
    { id: 1, name: 'NO' }
  ];
  const [showPreDataSelect, setShowPreDataSelect] = useState(false);
  const [previousDataList, setPreviousDataList] = useState([]);
  const [previousDataDetails, setPreviousDataDetails] = useState(null);
  const [showDiseCodeField, setShowDiseCodeField] = useState(true);

  const dateFormat = "DD-MM-YYYY";

  useEffect(() => {
    if (location.state === null) {
      navigate("/login");
    } else {
      setState({
        ...state,
        user_name: location.state.user_name,
        mobile_number: location.state.mobile_number,
      });

      form.setFieldsValue({
        user_name: location.state.user_name,
        mobile_number: location.state.mobile_number
      })
    }

    const getUtilityArrays = async () => {
      const apiResponse = await postRequest("get-utility-arrays");
      setDesignation(apiResponse.data.response.designation);
      setGender(apiResponse.data.response.gender);
      setReligion(apiResponse.data.response.religion);
      setDistrictCode(apiResponse.data.response.district);
    }
    getUtilityArrays();
  }, []);

  const handleDesignationChange = async (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const getSubDivisionList = async (distCode) => {
    const subDivnList = await postRequest("get-sub-division-by-district", {
      district: distCode,
    });

    setSubDivisionList(subDivnList.data.response);
  }

  const handleDistrictChange = async (field, value, field1) => {
    setState({ ...state, [field]: value, [field1]: [] });
    form.setFieldsValue({ field1: [] });

    getSubDivisionList(value);
  };

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value.toUpperCase() });
    form.setFieldsValue({ field: value.target.value.toUpperCase() });
  };

  const handleEmailChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
    form.setFieldsValue({ field: value.target.value });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const handleMemberTypeChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });

    if (value === 1) {
      setShowPreDataSelect(false);
      setState({ ...state, previous_data_id: null });
      setPreviousDataDetails(null)
    } else {
      setShowPreDataSelect(true);
      getPreDataList();
    }
  };

  const getPreDataList = async () => {
    const apiResponse = await postRequest("get-previous-data-name-list");
    setPreviousDataList(apiResponse.data.response.list);
  }

  const handlePreDataSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });

    const getPreDataDetails = async () => {
      const apiResponse = await postRequest("get-previous-data-details", {
        id: value
      });
      setPreviousDataDetails(apiResponse.data.response);
    }
    getPreDataDetails();
  };

  const handleDateChange = (field, dateString) => {
    setState({ ...state, [field]: dateString });
    form.setFieldsValue({ field: state.birth_date });
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const beforeCropFeature = async (file) => {
    if (isImageOrFile(file.type)) {
      const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };
      const compressedFile = await imageCompression(file, options)
      if (compressedFile.size > 1048576) {
        ErrorNotificationMsg("Maximum size for file upload is 1MB.");
        return false;
      }
      return true;
    } else {
      ErrorNotificationMsg("Supported file types are jpg, jpeg");
      return false;
    }
  };

  const customRequest = async ({ file: file }) => {
    let photoObj = {
      fileName: file.name,
      ext: "." + file.name.split(".").pop(),
    };

    getBase64(file, (imageUrl) => (photoObj.url = imageUrl));
    getBase64(file, (imageUrl) => (photoObj.file = imageUrl.replace("data:", "").replace(/^.+,/, "")));
    await sleep(300);

    setState({ ...state, photo_file: photoObj });
  }

  const handlePhotoRemove = async () => {
    setState({
      ...state,
      photo_file: {
        fileName: "",
        ext: "",
        file: "",
        url: userIcon,
      }
    });
  }


  const onFinish = async () => {
    setBtnLoading(true);

    const payload = {
      user_name: state.user_name,
      designation: state.designation,
      birth_date: moment(state.birth_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      gender: state.gender,
      religion: state.religion,
      father_husband: state.father_husband,

      postal_address: state.postal_address,
      district_code: state.district_code,
      pin_code: state.pin_code,

      mobile_number: state.mobile_number,
      whatsapp_number: state.whatsapp_number,
      email_id: state.email_id,

      school_name: state.school_name,
      school_district: state.school_district,
      school_sub_division: state.school_sub_division,

      photo_file: state.photo_file,

      service_status: state.service_status,
      member_type: state.member_type,
      previous_data_id: state.previous_data_id
    };

    try {
      const apiResponse = await postRequest("user-update", payload);

      if (apiResponse.data.error === 0) {
        SuccessNotificationMsg("Success", "Profile updated successfully.");
        setBtnLoading(false);
        navigate("/");
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const regExpCharacter = /^[a-zA-Z\.\s]*$/;
  const regExpNumber = /^\d+$/;

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-id-card"></i> Profile Details
                </h1>
              </div>

              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <div className="panel-content">
                  <div className="panel-tag px-3 py-2"><strong>Personal Details</strong></div>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Name"
                        name="user_name"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpCharacter),
                            message: "Please enter your name!",
                          },
                        ]}
                      >
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Service Status"
                        name="service_status"
                        rules={[
                          {
                            required: true,
                            message: "Please select service status!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select service status"
                          onChange={(value) =>
                            handleSelectChange("service_status", value)
                          }
                        >
                          {!!serviceStatus &&
                            serviceStatus.map((ss) => (
                              <Option key={ss.id} value={ss.id}>
                                {ss.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Designation"
                        name="designation"
                        rules={[
                          {
                            required: true,
                            message: "Please select designation!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Designation"
                          onChange={(value) =>
                            handleDesignationChange("designation", value)
                          }
                        >
                          {!!designation &&
                            designation.map((des) => (
                              <Option key={des.id} value={des.id}>
                                {des.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="Name of Father/Husband"
                        name="father_husband"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpCharacter),
                            message: "Please enter father/husband name!",
                          },
                        ]}
                      >
                        <Input onChange={(value) => handleChange("father_husband", value)} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        name="birth_date"
                        label="Date of Birth"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date of birth!",
                          },
                        ]}
                      >
                        <DatePicker
                          allowClear={false}
                          format={dateFormat}
                          onChange={(value) => handleDateChange("birth_date", value)}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Gender"
                          onChange={(value) =>
                            handleSelectChange("gender", value)
                          }
                        >
                          {!!gender &&
                            gender.map((gen) => (
                              <Option key={gen.id} value={gen.id}>
                                {gen.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="Religion"
                        name="religion"
                        rules={[
                          {
                            required: true,
                            message: "Please select religion!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Religion"
                          onChange={(value) =>
                            handleSelectChange("religion", value)
                          }
                        >
                          {!!religion &&
                            religion.map((religion) => (
                              <Option key={religion.id} value={religion.id}>
                                {religion.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="panel-tag px-3 py-2"><strong>Contact Details</strong></div>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item
                        label="Address"
                        name="postal_address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter address!",
                          },
                        ]}
                      >
                        <Input onChange={(value) => handleChange("postal_address", value)} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="District"
                        name="district_code"
                        rules={[
                          {
                            required: true,
                            message: "Please select district!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select District"
                          onChange={(value) =>
                            handleSelectChange("district_code", value)
                          }
                        >
                          {!!districtCode &&
                            districtCode.map((dist) => (
                              <Option key={dist.id} value={dist.id}>
                                {dist.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="PIN Code"
                        name="pin_code"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpNumber),
                            message: "Please enter PIN code!",
                          },
                        ]}
                      >
                        <Input onChange={(value) => handleChange("pin_code", value)} maxLength="6" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[15]}>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="Mobile No."
                        name="mobile_number"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpNumber),
                            message: "Please enter mobile number!",
                          },
                        ]}
                      >
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="Whatsapp No."
                        name="whatsapp_number"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpNumber),
                            message: "Please enter whatsapp number!",
                          },
                        ]}
                      >
                        <Input onChange={(value) => handleChange("whatsapp_number", value)} maxLength="10" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} lg={6}>
                      <Form.Item
                        label="E-mail"
                        name="email_id"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter valid email"
                          }
                        ]}
                      >
                        <Input onChange={(value) => handleEmailChange("email_id", value)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="panel-tag px-3 py-2"><strong>Present / Last Service Details</strong></div>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Name of your current / last school"
                        name="school_name"
                        rules={[
                          {
                            type: "text",
                            required: true,
                            message: "Please enter your current / last school name"
                          }
                        ]}
                      >
                        <Input onChange={(value) => handleChange("school_name", value)} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="School District"
                        name="school_district"
                        rules={[
                          {
                            required: true,
                            message: "Please select district of current school!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select district of current school"
                          onChange={(value) =>
                            handleDistrictChange("school_district", value, "school_sub_division")
                          }
                        >
                          {!!districtCode &&
                            districtCode.map((dc) => (
                              <Option key={dc.id} value={dc.id}>
                                {dc.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="School Sub-Division"
                        name="school_sub_division"
                        rules={[
                          {
                            required: true,
                            message: "Please select sub-division of current school!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select sub-division of current school"
                          onChange={(value) =>
                            handleSelectChange("school_sub_division", value)
                          }
                        >
                          {!!subDivisionList &&
                            subDivisionList.map((sdnl) => (
                              <Option key={sdnl.id} value={sdnl.id}>
                                {sdnl.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="panel-tag px-3 py-2"><strong>Member Details</strong></div>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Are your an existing member?"
                        name="member_type"
                        rules={[
                          {
                            required: true,
                            message: "Please select member type!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Are your an existing member?"
                          onChange={(value) =>
                            handleMemberTypeChange("member_type", value)
                          }
                        >
                          {!!memberType &&
                            memberType.map((mt) => (
                              <Option key={mt.id} value={mt.id}>
                                {mt.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} lg={16}>
                      {showPreDataSelect &&
                        <Form.Item
                          label="Choose you details"
                          name="previous_data_id"
                          rules={[
                            {
                              required: true,
                              message: "Please choose your details!",
                            },
                          ]}
                        >
                          <Select
                            showSearch={true}
                            placeholder="Select your details"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={(value) =>
                              handlePreDataSelectChange("previous_data_id", value)
                            }
                          >
                            {!!previousDataList &&
                              previousDataList.map((pdl) => (
                                <Option key={pdl.id} value={pdl.id}>
                                  {pdl.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }
                    </Col>
                  </Row>
                  <Row gutter={[15]}>
                    <Col xs={24} sm={8} lg={8}>
                    </Col>
                    <Col xs={24} sm={16} lg={16}>
                      {previousDataDetails !== null ? (
                        <>
                          <span className="d-block">Last paid upto : <strong>{previousDataDetails.lastPaid}</strong></span>
                          <span className="d-block">Subscribed for : <strong>{previousDataDetails.subType}</strong></span>
                          <span className="d-block mb-3">School : <strong>{previousDataDetails.schoolName}</strong></span>
                        </>
                      ) : ""}
                    </Col>
                  </Row>

                  <Row gutter={[15]}>
                    <Col xs={24} sm={24} lg={24}>
                      <div className="panel-tag px-3 py-2"><strong>Profile Photo</strong></div>
                      <Row gutter={[15]}>
                        <Col xs={24} sm={4} lg={4}>
                          <div className="card border m-auto m-lg-0">
                            <img
                              src={state.photo_file?.url}
                              className="img-thumbnail"
                              alt="Profile"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = userIcon;
                              }}
                            />
                          </div>
                          <ImgCrop
                            rotate={true}
                            aspect={1 / 1}
                            beforeCrop={beforeCropFeature}
                          >
                            <Upload
                              customRequest={customRequest}
                              onRemove={handlePhotoRemove}
                              maxCount={1}
                            >
                              <Button type="secondary">Select Photo</Button>
                            </Upload>
                          </ImgCrop>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div class="row no-gutters">
                  <div class="col-md-12 ml-auto text-right">
                    <Button
                      className="mt-3"
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        setState({ ...state })
                      }
                      loading={btnLoading}
                    >
                      Submit
                    </Button>
                    <Button
                      className="ml-2 mt-3"
                      type="secondary"
                      htmlType="button"
                      onClick={() => navigate("/login")}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNew;