import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  getUserData,
  getUserType,
  logout,
} from "../../utils/Helpers";
import SidebarMember from "./SidebarMember";
import SidebarState from "./SidebarState";
import SidebarDistrict from "./SidebarDistrict";
import SidebarSubDivision from "./SidebarSubDivision";

import ChangePassword from "../authentication/ChangePassword";

import "antd/dist/antd.min.css";

const AdminLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    if (window.innerWidth > 860 && isSidebarOpen) {
      return true;
    }
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    toggleSidebar();
  }, [])

  return (
    <div className="page-wrapper mod-nav-link">
      <div className="page-inner">
        {getUserType() === "1" ? (
          <SidebarState
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        ) : (getUserType() === "2" ? (
          <SidebarDistrict
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        ) : (getUserType() === "3" ? (
          <SidebarSubDivision
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        ) : (
          <SidebarMember
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )))}

        <div className="page-content-wrapper">
          <header className="page-header">
            {window.innerWidth < 860 ?
              <div className="hidden-lg-up">
                <a href="#" onClick={toggleSidebar} className="header-btn btn waves-effect waves-themed" data-action="toggle" data-class="mobile-nav-on">
                  <i className="ni ni-menu"></i>
                </a>
              </div>
              :
              <div className="hidden-md-down position-relative">
                <a href="#" onClick={toggleSidebar} className="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden" title="Hide Navigation">
                  <i className="ni ni-menu"></i>
                </a>
              </div>
            }

            <div className="ml-auto d-flex">
              <div>
                <a
                  href="#"
                  data-toggle="dropdown"
                  title={getUserData()?.user_name}
                  className="header-icon d-flex align-items-center justify-content-center ml-2 "
                >
                  <img
                    src={getUserData()?.photo_file}
                    className="profile-image rounded-circle"
                    alt={getUserData()?.user_name}
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                  <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                    <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                      <span className="mr-2">
                        <img
                          src={getUserData()?.photo_file}
                          className="rounded-circle profile-image"
                          alt={getUserData()?.user_name}
                        />
                      </span>
                      <div className="info-card-text">
                        <div className="fs-lg break-text">
                          {getUserData()?.user_name}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider m-0"></div>
                  <ChangePassword />
                  <div className="dropdown-divider m-0"></div>
                  <span
                    onClick={logout}
                    className="dropdown-item fw-500 pt-3 pb-3"
                  >
                    <span>Logout</span>
                  </span>
                </div>
              </div>
            </div>
          </header>

          <main id="js-page-content" role="main" class="page-content">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;