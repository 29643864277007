import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Form } from "antd";
import Password from "antd/lib/input/Password";
import { postRequest } from "../../axios";

import Config from "../../Config";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { redirectIfLoggedIn } from "../../utils/Helpers";

const Login = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    mobile_number: null,
    password: null,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const onLogin = async () => {
    setBtnLoading(true);

    try {
      const loginResponse = await postRequest("login", state);

      if (
        loginResponse.data &&
        loginResponse.data.error === 0
      ) {
        localStorage.clear();
        localStorage.setItem("restoken", loginResponse.data.response.token.access_token);

        const userDetailResponse = await axios.post(
          Config.API_URL + "get-login-details",
          state,
          {
            headers: {
              Authorization: `Bearer ${loginResponse.data.response.token.access_token}`,
            },
          }
        );

        let userData = {
          uid: userDetailResponse.data.response.id,
          user_name: userDetailResponse.data.response.user_name,
          mobile_number: userDetailResponse.data.response.mobile_number,
          user_type: userDetailResponse.data.response.user_type,
          user_type_code: userDetailResponse.data.response.user_type_code,
          designation: userDetailResponse.data.response.designation,
          photo_file: userDetailResponse.data.response.photo_file,
          finYear: '2022'
        };

        localStorage.setItem("regStep", userDetailResponse.data.response.reg_step);
        localStorage.setItem("userType", userDetailResponse.data.response.user_type);
        localStorage.setItem("userData", JSON.stringify(userData));

        SuccessNotificationMsg("Success", "Succesfully logged in!");
        setBtnLoading(false);

        if (userDetailResponse.data.response.reg_step === 1) {
          navigate(
            "/profile-new",
            {
              state: {
                user_name: userDetailResponse.data.response.user_name,
                mobile_number: userDetailResponse.data.response.mobile_number,
              }
            },
          );
        } else {
          window.location.href = "/dashboard";
        }
      } else {
        ErrorNotificationMsg(loginResponse.data.errmsg);
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  };

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-sign-in-alt"></i> Sign In
                </h1>
              </div>

              <Form onFinish={onLogin} autoComplete="off" layout="vertical">
                <Form.Item
                  label="Registered Mobile Number"
                  name="mobile_number"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[0-9+]{0,10}$"),
                      message: "Please enter registered mobile number",
                    },
                  ]}
                >
                  <Input
                    onChange={(value) => handleChange("mobile_number", value)}
                    placeholder="Enter registered mobile number"
                    maxLength="10"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Password
                    onChange={(value) => handleChange("password", value)}
                    placeholder="Enter password"
                  />
                </Form.Item>

                <div class="row no-gutters">
                  <div class="col-md-12 ml-auto text-right">
                    <Button className="mt-3" type="primary" htmlType="submit" loading={btnLoading}>
                      Sign In
                    </Button>
                  </div>
                </div>
              </Form>

              <div className="border-top mt-4">
                <div className="text-center mt-3">
                  Not Registered?&nbsp;
                  <Link to="/register-instruction" className="btn btn-xs btn-danger">
                    <strong>Register Now!</strong>
                  </Link>
                </div>

                <div className="blankpage-footer text-center">
                  <Link to="/forgot-password">
                    <strong>Forgot Password?</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Login;
