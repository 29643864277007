import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";

import { validateToken, redirectIfNotLoggedIn, getUserType } from "../../utils/Helpers";

const PrivateRoute = ({ page, role }) => {
  const [spinner, setSpinner] = useState(true);

  const authCheck = () => {
    redirectIfNotLoggedIn();
    return validateToken(localStorage.getItem("restoken"));
  };

  let checkRole = false;
  if (role && getUserType() !== role) {
    checkRole = true;
  }

  let spinStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    verticalAlign: 'middle'
  };

  return (
    <>
      {checkRole && (<Navigate to="/no-permission" />)}
      {authCheck() ?
        <Spin spinning={spinner} tip="Loading..." size="large" style={spinStyle}>
          <Outlet context={setSpinner} />
        </Spin>
        : <Navigate to="/" />}
    </>
  );
}

export default PrivateRoute;
