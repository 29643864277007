import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { validateToken } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import "antd/dist/antd.min.css";
import "../../css/page-login-alt.css";

const ErrorLayout = () => {
  const [state, setState] = useState({
    loginStatus: false,
    visible: false,
  })

  // showDrawer = () => {
  //   this.setState({
  //     visible: true,
  //   });
  // };

  // onClose = () => {
  //   this.setState({
  //     visible: false,
  //   });
  // };

  useEffect(() => {
    const loginStatus = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY)
    );
    setState({ loginStatus });
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

export default ErrorLayout;
