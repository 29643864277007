import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Space, Button, DatePicker } from "antd";

import PageHeader from "../common/PageHeader";
import { getUserData, getUserType } from "../../utils/Helpers";
import { postRequest } from "../../axios";
import { useOutletContext } from "react-router-dom";
import ReceiptInformationShow from "./ReceiptInformationShow";
import moment from "moment";

const { Option } = Select;

const dateFormat = "DD-MM-YYYY";

const ReceiptInformation = () => {
  const setSpinner = useOutletContext();
  const [state, setState] = useState({
    dist_code: getUserData().user_type_code,
    date_from: moment().format("DD-MM-YYYY"),
    date_to: moment().format("DD-MM-YYYY"),
  });
  const [form] = Form.useForm();
  const [showList, setShowList] = useState(false);
  const [districtList, setDistrictList] = useState([]);

  useEffect(() => {
    if (getUserType() === "1") {
      setSpinner(true);

      const getUtilityArrays = async () => {
        const apiResponse = await postRequest("get-utility-arrays");
        setDistrictList(apiResponse.data.response.district);

        setSpinner(false);
      }
      getUtilityArrays();
    } else {
      setState({ ...state, dist_code: getUserData().user_type_code });
    }
  }, []);

  const handleDateChange = (field, dateString) => {
    setState({ ...state, [field]: dateString });
    form.setFieldsValue({ field: dateString });
    
    setShowList(false);
  };

  const handleSchoolDistrictChange = async (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });

    setShowList(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Members"
        pageIcon={<i className="subheader-icon fal fa-users"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Receipt Information</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">

                <Form
                  form={form}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="date_from"
                        label="Date From"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date from!",
                          },
                        ]}
                      >
                        <DatePicker
                          allowClear={false}
                          format={dateFormat}
                          onChange={(value) => handleDateChange("date_from", value)}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="date_to"
                        label="Date To"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date to!",
                          },
                        ]}
                      >
                        <DatePicker
                          allowClear={false}
                          format={dateFormat}
                          onChange={(value) => handleDateChange("date_to", value)}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    {getUserType() === "1" &&
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          name="dist_code"
                          label="Select District"
                        >
                          <Select
                            allowClear
                            placeholder="Select District"
                            className="form-control"
                            onChange={(value) =>
                              handleSchoolDistrictChange("dist_code", value)
                            }
                          >
                            {!!districtList &&
                              districtList.map((dist) => (
                                <Option key={dist.id} value={dist.id}>
                                  {dist.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    }
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        setShowList(true)
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Information
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showList && (
        <ReceiptInformationShow
          state={state}
          showList={setShowList}
        />
      )}
    </div>
  );
};

export default ReceiptInformation;