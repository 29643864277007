import React, { useState, useEffect } from "react";
import { postRequest } from "../../axios";
import moment from "moment";

import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import FeesPdfModal from "./FeesPdfModal";

const FeesPaid = (props) => {
  const setSpinner = useOutletContext();
  const [feesPaidList, setFeesPaidList] = useState([]);

  useEffect(() => {
    const getFeesPaidList = async () => {
      setSpinner(true);

      try {
        const apiResponse = await postRequest("get-fees-paid-user", {
          uid: props.uid
        });

        if (apiResponse.data.error === 0) {
          setFeesPaidList(apiResponse.data.response);
        }
      } catch (error) {
        ErrorNotificationMsg(error.errmsg);
      }
      setSpinner(false);
    }
    getFeesPaidList();
  }, [setSpinner, props.uid]);

  return (
    <div id="panel-2" className="panel">
      <div className="panel-hdr">
        <h2>Paid Fees Details</h2>
      </div>
      <div className="panel-container show">
        <div className="panel-content">
          {feesPaidList.length > 0 ?
            <>
              {feesPaidList.map((fees, fid) => {
                let feeList = fees.fees_details;
                var total = 0;
                return (
                  <table className="table table-sm table-bordered table-hover" key={fid}>
                    <thead className="thead-themed">
                      <tr>
                        <th colSpan={2}>
                          <span className="d-block">
                            Receipt No. : {fees?.receipt_no}
                          </span>
                          <span className="d-block">
                            Date :{" "}
                            {moment(fees?.receipt_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                          <span className="d-block">
                            Mode : {fees.paid_mode}
                          </span>
                          {fees.paid_remarks !== "" && (
                            <span className="d-block">
                              Remarks : {fees.paid_remarks}
                            </span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {feeList.map((amt, mid) => {
                        let fd = amt.feesAmount;
                        return (
                          <>
                            {
                              fd.map((fee, pid) => {
                                total = total + parseInt(fee.amount);
                                return (
                                  <>
                                    {fd.length > 1 && pid === 0 &&
                                      <>
                                        <tr><td colSpan={2}>Year : <strong>{amt.finYear}</strong></td></tr>
                                      </>
                                    }
                                    {fd.length === 1 &&
                                      <>
                                        <tr><td>Year : <strong>{amt.finYear}</strong></td></tr>
                                      </>
                                    }
                                    <tr key={"p" + fid + mid + pid}>
                                      <td>{fee.feesName}</td>
                                      <td className="text-right">
                                        <i className="fal fa-rupee-sign"></i>
                                        <span>{" "}{fee.amount}</span>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            }
                          </>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className="text-right">Total -</th>
                        <td className="text-right">
                          <i className="fal fa-rupee-sign"></i>{" "}
                          <strong>{total}</strong>
                        </td>
                      </tr>
                      {total > 0 && (
                        <tr>
                          <td colSpan={2}>
                            <FeesPdfModal
                              receiptId={fees.id}
                              copyType={1}
                              buttonText="Print Receipt"
                              buttonClassName="btn btn-block btn-warning btn-sm"
                            />
                          </td>
                        </tr>
                      )}
                    </tfoot>
                  </table>
                );
              })}
            </>
            :
            <div className="alert alert-info fade show px-3 py-2" role="alert">
              No fees paid
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FeesPaid;