import React, { useState } from "react";
import { Modal } from "antd";
import { useOutletContext } from "react-router-dom";
import { postRequest } from "../../axios";

const FeesPdfModal = (props) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const setSpinner = useOutletContext();

  const printReceipt = async () => {
    setSpinner(true);
    const receiptResponse = await postRequest("get-fees-receipt-pdf", {
      receiptId: props.receiptId,
      copyType: props.copyType,
    });
    setSpinner(false);
    setFileUrl(receiptResponse.data.response.filePath);
  };

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    printReceipt();
    setShowModel(true);
  };

  return (
    <>
      <button
        className={props.buttonClassName}
        onClick={() => showModelFunction()}
      >
        {props.buttonText}
      </button>

      <Modal
        title="Fees Receipt"
        visible={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe title="Fees Receipt" src={fileUrl} style={{height: "400px"}}></iframe>
      </Modal>
    </>
  );
};

export default FeesPdfModal;