import React, { useEffect, useState } from "react";

import { postRequest } from "../../axios";
import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Button,
  Modal,
} from "antd";
const { Option } = Select;

const ReceiptAmount = (props) => {
  const setSpinner = useOutletContext();
  const [form] = Form.useForm();
  const [dueDetails, setDueDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [state, setState] = useState({
    paid_mode: 0,
    paid_remarks: null
  })
  const paidMode = [
    { id: 0, name: "CASH" },
    { id: 1, name: "BANK" },
    { id: 2, name: "ONLINE" },
  ];
  const [fileUrl, setFileUrl] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getDueDetails = async () => {
      setSpinner(true);

      const apiResponse = await postRequest("get-fees-due-mobile-number", {
        mobile_number: props.mobileNo
      });

      if (apiResponse.data.error === 0) {
        setDueDetails(apiResponse.data.response.due);
        setUserDetails(apiResponse.data.response.user);
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
      setSpinner(false);
    }
    getDueDetails();
  }, [props]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
    form.setFieldsValue({ field: value.target.value });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const onFinish = async () => {
    setBtnLoading(true);

    const payload = {
      uid: userDetails.uid,
      paid_mode: state.paid_mode,
      paid_remarks: state.paid_remarks
    };

    try {
      const apiResponse = await postRequest("fees-generate-receipt", payload);

      if (apiResponse.data.error === 0) {
        setBtnLoading(false);

        setFileUrl(apiResponse.data.response.filePath);
        setShowModal(true);
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const hideModelFunction = () => {
    setShowModal(false);
    props.showAmount(false);
  };

  var totalDue = 0;

  return (
    <>
      {userDetails.length !== 0 &&
        <>
          <span className="d-block">Name : <strong>{userDetails.name}</strong></span>
          <span className="d-block">Designation : <strong>{userDetails.designation}</strong></span>
          <span className="d-block">School : <strong>{userDetails.currentSchool}</strong></span>
          <span className="d-block">District : <strong>{userDetails.district}</strong></span>
          <br />

          {dueDetails.length > 0 ?
            <>
              <table className="table table-sm table-bordered table-hover">
                <thead className="thead-themed text-center">
                  <tr>
                    <th>SL</th>
                    <th>YEAR</th>
                    <th>DISCRIPTION</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {dueDetails.map((fees, fid) => {
                    let amtList = fees.feesAmount;
                    return (
                      <>
                        {amtList.map((amt, mid) => {
                          totalDue = totalDue + parseInt(amt.amount);
                          return (
                            <tr key={"f" + fid + mid}>
                              {amtList.length > 1 && mid === 0 &&
                                <>
                                  <td rowSpan={amtList.length} className="text-right">{fid + 1}</td>
                                  <td rowSpan={amtList.length} className="text-center">{fees.finYear}</td>
                                </>
                              }
                              {amtList.length === 1 &&
                                <>
                                  <td className="text-right">{fid + 1}</td>
                                  <td className="text-center">{fees.finYear}</td>
                                </>
                              }
                              <td>{amt.feesName}</td>
                              <td className="text-right">
                                <i className="fal fa-rupee-sign"></i>
                                <span>{" "}{amt.amount}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={3} className="text-right">Total -</th>
                    <td className="text-right">
                      <i className="fal fa-rupee-sign"></i>
                      <span><strong>{" "}{totalDue}</strong></span>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Row gutter={[15]} className="mb-3">
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      name="paid_mode"
                      label="Mode of Payment"
                      rules={[
                        {
                          required: true,
                          message: "Please select mode of payment!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Mode of Payment"
                        className="form-control"
                        onChange={(value) =>
                          handleSelectChange("paid_mode", value)
                        }
                      >
                        {!!paidMode &&
                          paidMode.map((pm) => (
                            <Option key={pm.id} value={pm.id}>
                              {pm.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={16} lg={16}>
                    <Form.Item
                      label="Remarks"
                      name="paid_remarks"
                    >
                      <Input onChange={(value) => handleChange("paid_remarks", value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-content mt-2 d-flex flex-row justify-content-end">
                  <div class="row no-gutters">
                    <div class="col-md-12 ml-auto text-right">
                      <Button
                        className="mt-3"
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          setState({ ...state })
                        }
                        loading={btnLoading}
                      >
                        Genrate Receipt
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
            :
            <div className="alert alert-info fade show px-3 py-2" role="alert">
              No fees due
            </div>
          }
        </>
      }

      <Modal
        title="Fees Receipt"
        visible={showModal}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe title="Fees Receipt" src={fileUrl} style={{ height: "400px" }}></iframe>
      </Modal>
    </>
  );
};

export default ReceiptAmount;