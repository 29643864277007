import React, { useEffect, useState } from "react";
import { postRequest } from "../../axios";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  Modal,
  Space
} from "antd";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const UpdateLastPaid = (props) => {
  const setSpinner = useOutletContext();
  const [form] = Form.useForm();
  const [state, setState] = useState({
    fin_year: null
  })

  const finYear = [
    { id: '2018-2019', name: '2018-2019' },
    { id: '2019-2020', name: '2019-2020' },
    { id: '2020-2021', name: '2020-2021' },
    { id: '2021-2022', name: '2021-2022' },
    { id: '2022-2023', name: '2022-2023' },
  ];
  const [btnLoading, setBtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setSpinner(false);
    handlePageLoad();
  }, [setSpinner]);

  const handlePageLoad = () => {
    setState({ ...state, fin_year: props.previousData.lastPaid });
    form.setFieldsValue({
      fin_year: props.previousData.lastPaid
    })
  }

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const onFinish = async () => {
    setBtnLoading(true);

    const payload = {
      previous_data_id: props.previousData.id,
      fin_year: state.fin_year,
    };

    try {
      const apiResponse = await postRequest("update-previous-data-fin-year", payload);

      if (apiResponse.data.error === 0) {
        SuccessNotificationMsg("Success", "Profile updated successfully.");
        setBtnLoading(false);

        props.getPreDataDetails(props.previousData.id);
        hideModelFunction();
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const hideModelFunction = () => {
    setShowModal(false);
  };

  const showModelFunction = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        onClick={() => showModelFunction()}
        className="btn btn-xs btn-warning ml-3"
        type="submit"
      >
        Update
      </button>

      <Modal
        title="Update Last Paid"
        visible={showModal}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[15]} className="mb-3">
            <Col xs={24} sm={8} lg={8}>
              <Form.Item
                name="fin_year"
                label="Select Financial Year"
                rules={[
                  {
                    required: true,
                    message: "Please select financial year!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Financial Year"
                  className="form-control"
                  onChange={(value) =>
                    handleSelectChange("fin_year", value)
                  }
                >
                  {!!finYear &&
                    finYear.map((fy) => (
                      <Option key={fy.id} value={fy.id}>
                        {fy.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                className="mt-2"
              >
                Submit
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateLastPaid;