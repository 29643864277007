import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Button,
  DatePicker,
  Typography,
} from "antd";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { postRequest } from "../../axios";
import { getUserData } from "../../utils/Helpers";
import PageHeader from "../common/PageHeader";

const { Option } = Select;

const ProfileEdit = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Title } = Typography;

  const [state, setState] = useState({
    user_name: null,
    designation: [],
    birth_date: moment().format("DD-MM-YYYY"),
    gender: [],
    religion: [],
    father_husband: null,

    postal_address: null,
    district_code: [],
    pin_code: null,

    whatsapp_number: null,
    email_id: null,

    school_name: null,
    school_district: null,
    school_sub_division: null,

    service_status: [],
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  const [designation, setDesignation] = useState([]);
  const [gender, setGender] = useState([]);
  const [religion, setReligion] = useState([]);
  const [districtCode, setDistrictCode] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [schoolName, setSchoolName] = useState([]);
  const serviceStatus = [
    { id: 0, name: 'In Service' },
    { id: 1, name: 'Retired' }
  ];
  const [showDiseCodeField, setShowDiseCodeField] = useState(true);

  const dateFormat = "DD-MM-YYYY";

  useEffect(() => {
    const getUtilityArrays = async () => {
      const apiResponse = await postRequest("get-utility-arrays");
      setDesignation(apiResponse.data.response.designation);
      setGender(apiResponse.data.response.gender);
      setReligion(apiResponse.data.response.religion);
      setDistrictCode(apiResponse.data.response.district);
    }
    getUtilityArrays();
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      setSpinner(true);
      const apiResponse = await postRequest("user-details", {
        uid: getUserData().uid
      });

      if (apiResponse.data.error === 0) {
        const resData = apiResponse.data.response;

        setState({
          user_name: resData.user_name,
          designation: resData.designation,
          birth_date: resData.birth_date !== "" ? resData.birth_date : "",
          gender: resData.gender,
          religion: resData.religion,
          father_husband: resData.father_husband,

          postal_address: resData.postal_address,
          district_code: resData.district_code,
          pin_code: resData.pin_code,

          whatsapp_number: resData.whatsapp_number,
          email_id: resData.email,

          school_name: resData.school_name,
          school_district: resData.school_district,
          school_sub_division: resData.school_sub_division,

          service_status: resData.service_status,
        });

        if (resData.can_edit === 1) {
          form.setFieldsValue({
            user_name: resData.user_name,
            designation: resData.designation,
            birth_date: resData.birth_date !== "" ? moment(resData.birth_date, "DD-MM-YYYY") : "",
            gender: resData.gender,
            religion: resData.religion,
            father_husband: resData.father_husband,

            postal_address: resData.postal_address,
            district_code: resData.district_code,
            pin_code: resData.pin_code,

            whatsapp_number: resData.whatsapp_number,
            email_id: resData.email,

            school_name: resData.school_name,
            school_district: resData.school_district,
            school_sub_division: resData.school_sub_division,

            service_status: resData.service_status,
          });
        }

        setSpinner(false);

        getSubDivisionList(resData.school_district);
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    }

    getUserProfile();
  }, []);

  const handleDesignationChange = async (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const getSubDivisionList = async (distCode) => {
    const subDivnList = await postRequest("get-sub-division-by-district", {
      district: distCode,
    });

    setSubDivisionList(subDivnList.data.response);
  }

  const handleDistrictChange = async (field, value, field1) => {
    setState({ ...state, [field]: value, [field1]: [] });
    form.setFieldsValue({ field1: [] });

    getSubDivisionList(value);
  };

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value.toUpperCase() });
    form.setFieldsValue({ field: value.target.value.toUpperCase() });
  };

  const handleEmailChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
    form.setFieldsValue({ field: value.target.value });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    form.setFieldsValue({ field: value });
  };

  const handleDateChange = (field, dateString) => {
    setState({ ...state, [field]: dateString });
    form.setFieldsValue({ field: state.birth_date });
  };

  const onFinish = async () => {
    setBtnLoading(true);

    const payload = {
      uid: getUserData().uid,
      user_name: state.user_name,
      designation: state.designation,
      birth_date: moment(state.birth_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      gender: state.gender,
      religion: state.religion,
      father_husband: state.father_husband,

      postal_address: state.postal_address,
      district_code: state.district_code,
      pin_code: state.pin_code,

      whatsapp_number: state.whatsapp_number,
      email_id: state.email_id,

      school_name: state.school_name,
      school_district: state.school_district,
      school_sub_division: state.school_sub_division,

      service_status: state.service_status,
    };

    try {
      const apiResponse = await postRequest("user-edit-update", payload);

      if (apiResponse.data.error === 0) {
        SuccessNotificationMsg("Success", "Profile updated successfully.");
        setBtnLoading(false);
        navigate("/profile-details");
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const regExpCharacter = /^[a-zA-Z\.\s]*$/;
  const regExpNumber = /^\d+$/;

  return (
    <div id="content">
      <PageHeader
        pageTitle="Edit My Profile"
        pageIcon={<i className="subheader-icon fal fa-user"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Edit My Profile</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <div className="panel-content">
                    <div className="panel-tag px-3 py-2"><strong>Personal Details</strong></div>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="Name"
                          name="user_name"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(regExpCharacter),
                              message: "Please enter your name!",
                            },
                          ]}
                        >
                          <Input readOnly={true} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="Service Status"
                          name="service_status"
                          rules={[
                            {
                              required: true,
                              message: "Please select service status!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select service status"
                            onChange={(value) =>
                              handleSelectChange("service_status", value)
                            }
                          >
                            {!!serviceStatus &&
                              serviceStatus.map((ss) => (
                                <Option key={ss.id} value={ss.id}>
                                  {ss.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="Designation"
                          name="designation"
                          rules={[
                            {
                              required: true,
                              message: "Please select designation!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Designation"
                            onChange={(value) =>
                              handleDesignationChange("designation", value)
                            }
                          >
                            {!!designation &&
                              designation.map((des) => (
                                <Option key={des.id} value={des.id}>
                                  {des.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="Name of Father/Husband"
                          name="father_husband"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(regExpCharacter),
                              message: "Please enter father/husband name!",
                            },
                          ]}
                        >
                          <Input onChange={(value) => handleChange("father_husband", value)} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          name="birth_date"
                          label="Date of Birth"
                          rules={[
                            {
                              required: true,
                              message: "Please enter date of birth!",
                            },
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            format={dateFormat}
                            onChange={(value) => handleDateChange("birth_date", value)}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select gender!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Gender"
                            onChange={(value) =>
                              handleSelectChange("gender", value)
                            }
                          >
                            {!!gender &&
                              gender.map((gen) => (
                                <Option key={gen.id} value={gen.id}>
                                  {gen.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="Religion"
                          name="religion"
                          rules={[
                            {
                              required: true,
                              message: "Please select religion!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Religion"
                            onChange={(value) =>
                              handleSelectChange("religion", value)
                            }
                          >
                            {!!religion &&
                              religion.map((religion) => (
                                <Option key={religion.id} value={religion.id}>
                                  {religion.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="panel-tag px-3 py-2"><strong>Contact Details</strong></div>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item
                          label="Address"
                          name="postal_address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter address!",
                            },
                          ]}
                        >
                          <Input onChange={(value) => handleChange("postal_address", value)} />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="District"
                          name="district_code"
                          rules={[
                            {
                              required: true,
                              message: "Please select district!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select District"
                            onChange={(value) =>
                              handleSelectChange("district_code", value)
                            }
                          >
                            {!!districtCode &&
                              districtCode.map((dist) => (
                                <Option key={dist.id} value={dist.id}>
                                  {dist.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="PIN Code"
                          name="pin_code"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(regExpNumber),
                              message: "Please enter PIN code!",
                            },
                          ]}
                        >
                          <Input onChange={(value) => handleChange("pin_code", value)} maxLength="6" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[15]}>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="Whatsapp No."
                          name="whatsapp_number"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(regExpNumber),
                              message: "Please enter whatsapp number!",
                            },
                          ]}
                        >
                          <Input onChange={(value) => handleChange("whatsapp_number", value)} maxLength="10" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} lg={6}>
                        <Form.Item
                          label="E-mail"
                          name="email_id"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter valid email"
                            }
                          ]}
                        >
                          <Input onChange={(value) => handleEmailChange("email_id", value)} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="panel-tag px-3 py-2"><strong>Present / Last Service Details</strong></div>
                    <Row gutter={[15]}>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="Name of your current / last school"
                          name="school_name"
                          rules={[
                            {
                              type: "text",
                              required: true,
                              message: "Please enter your current / last school name"
                            }
                          ]}
                        >
                          <Input onChange={(value) => handleChange("school_name", value)} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="School District"
                          name="school_district"
                          rules={[
                            {
                              required: true,
                              message: "Please select district of current school!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select district of current school"
                            onChange={(value) =>
                              handleDistrictChange("school_district", value, "school_sub_division")
                            }
                          >
                            {!!districtCode &&
                              districtCode.map((dc) => (
                                <Option key={dc.id} value={dc.id}>
                                  {dc.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          label="School Sub-Division"
                          name="school_sub_division"
                          rules={[
                            {
                              required: true,
                              message: "Please select sub-division of current school!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select sub-division of current school"
                            onChange={(value) =>
                              handleSelectChange("school_sub_division", value)
                            }
                          >
                            {!!subDivisionList &&
                              subDivisionList.map((sdnl) => (
                                <Option key={sdnl.id} value={sdnl.id}>
                                  {sdnl.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div class="row no-gutters">
                    <div class="col-md-12 ml-auto text-right">
                      <Button
                        className="mt-3"
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          setState({ ...state })
                        }
                        loading={btnLoading}
                      >
                        Submit
                      </Button>
                      <Button
                        className="ml-2 mt-3"
                        type="secondary"
                        htmlType="button"
                        onClick={() => navigate("/login")}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;