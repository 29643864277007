import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Form } from "antd";
import { postRequest } from "../../axios";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { redirectIfLoggedIn } from "../../utils/Helpers";

const RegisterInstruction = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    user_name: null,
    mobile_number: null,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value.toUpperCase() });
    form.setFieldsValue({ field: value.target.value.toUpperCase() })
  };

  const onRegister = async () => {
    setBtnLoading(true);

    try {
      const apiResponse = await postRequest("register", state);

      if (apiResponse.data && apiResponse.data.error === 0) {
        if (apiResponse.data.response.step_completed > 0) {
          SuccessNotificationMsg(
            "Success",
            "Already registered! Please Sign-In"
          );
          setBtnLoading(false);

          navigate("/login");
        } else {
          SuccessNotificationMsg(
            "Success",
            "OTP successfully sent for verification!"
          );
          setBtnLoading(false);

          navigate(
            "/otp-verify",
            {
              state: {
                user_name: state.user_name,
                mobileNumber: apiResponse.data.response.mobile_number,
                type: "register"
              }
            }
          );
        }
      } else {
        ErrorNotificationMsg(apiResponse.data.response.errmsg);
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  };

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-8 col-md-8 col-sm-8 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-sign-in"></i> Instructions for Registration
                </h1>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <p>1)&nbsp;&nbsp;&nbsp;&nbsp;Members need to provide a Valid Mobile number for OTP verification & will receive the Login credentials on this number after registration.</p>
                <p>2)&nbsp;&nbsp;&nbsp;&nbsp;Members should know the DISE Code of their Current or Previous School to search and add to their profile.</p>
                <p>3)&nbsp;&nbsp;&nbsp;&nbsp;Members need to fill all the mandatory fields (fields marked with red *) with accurate data.</p>
                <p>4)&nbsp;&nbsp;&nbsp;&nbsp;Members will automatically get logged out on pressing the Submit button.</p>
                <p>5)&nbsp;&nbsp;&nbsp;&nbsp;Members can login using the login credentials sent through SMS on the registered mobile number and proceed with the Membership fee payment (if any due).</p>
                <p>6)&nbsp;&nbsp;&nbsp;&nbsp;For facilitating online payment, Payment Gateway charges to be paid extra. However, there are no charges for payment through UPI.</p>
                <p>7)&nbsp;&nbsp;&nbsp;&nbsp;In case of any discrepancy with the provided data, the Members can reach out to their respective District Representatives.</p>
              </div>

              <div class="row no-gutters">
                <div class="col-md-12 ml-auto text-right">
                  <Link to="/register" className="btn btn-primary">
                    <strong>Next</strong>
                  </Link>
                </div>
              </div>

              <div className="border-top mt-4">
                <div className="text-center mt-3">
                  Already Registered?&nbsp;
                  <Link to="/login" className="btn btn-xs btn-danger">
                    <strong>Sign-In</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterInstruction;