import React from "react";

const NotFound = () => {
  return (
    <div className="menu-on-top fixed-header smart-style-0">
      <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
        <h1 className="page-error color-fusion-500">
          <span className="text-gradient">Oooops</span>
          <small className="fw-500">
            No <u>PAGE</u> found!
          </small>
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
