import React from "react";
import { Link } from "react-router-dom";

import LogoSmall from "../../styles/LogoSmall";
import coverBG from "../../images/cover-2-lg.png";
import { getUserData } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";

const SidebarMember = (props) => {
  const toggleSidebar = () => {
    props.toggleSidebar();
  };

  return (
    <>
      <aside
        className={
          props.isSidebarOpen
            ? "page-sidebar sidebaropen"
            : "page-sidebar sidebarclose"
        }
      >
        <div className="page-logo">
          <Link to="/dashboard">
            <LogoSmall />
          </Link>

          {window.innerWidth < 860 && (
            <a href="#" onClick={toggleSidebar} className="btn btn-pills btn-danger" style={{ marginLeft: "15px" }} data-class="mobile-nav-on">
              <i className="ni ni-minify-nav" style={{ marginLeft: "-15px" }}></i>
            </a>
          )}
        </div>

        <nav id="js-primary-nav" className="primary-nav" role="navigation">
        <div className="info-card">
            <img
              src={getUserData()?.photo_file}
              className="profile-image rounded-circle"
              alt="User Image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <div className="info-card-text">
              <a className="d-flex align-items-center text-white">
                {getUserData().user_name}
              </a>
            </div>
            <img src={coverBG} className="cover" alt="cover" />

            <a href="#" className="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
              <i className="fal fa-angle-down"></i>
            </a>
          </div>

          <ul id="js-nav-menu" className="nav-menu">
            <li>
              <Link onClick={toggleSidebar} to="/dashboard">
                <i className="fal fa-home"></i>
                <span className="nav-link-text">Dashboard</span>
              </Link>
            </li>

            <li>
              <Link onClick={toggleSidebar} to="/profile-details">
                <i className="fal fa-user"></i>
                <span className="nav-link-text">My Profile</span>
              </Link>
            </li>

            <li>
              <Link onClick={toggleSidebar} to="/member-fees">
                <i className="fal fa-receipt"></i>
                <span className="nav-link-text">My Fees</span>
              </Link>
            </li>

          </ul>
        </nav>
      </aside>
    </>
  );
};

export default SidebarMember;
