import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, Form } from "antd";
import { postRequest } from "../../axios";

import {
  ErrorNotificationMsg, SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

const OtpVerify = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    mobile_number: location.state.mobileNumber,
    type: location.state.type
  });
  const [otp, setOtp] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const getOtp = async () => {
      const apiResponse = await postRequest("otp-send", state);
      if (apiResponse.data && apiResponse.data.error === 0) {
        setOtp(apiResponse.data.response.otp);
      } else {
        ErrorNotificationMsg(apiResponse.data.errmsg);
      }
    }
    getOtp();
  }, []);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const onSubmit = async () => {
    setBtnLoading(true);

    try {
      const payload = {
        "type": state.type,
        "mobile_number": state.mobile_number,
        "otp": otp
      }
      const apiResponse = await postRequest("otp-verify", payload);
      if (apiResponse.data && apiResponse.data.error === 0) {
        SuccessNotificationMsg(apiResponse.data.response);

        if (state.type==='register') {
          navigate(
            "/profile-new",
            {
              state: {
                user_name: location.state.user_name,
                mobile_number: state.mobile_number,
              }
            },
          );
        } else {
          navigate("/");
        }
      } else {
        ErrorNotificationMsg("Invalid OTP!");
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(error.errmsg);
    }
  };

  return (
    <div className="flex-1" >
      <div className="container py-4 py-lg-1 my-lg-5 px-4 px-sm-0 mb-lg-6">
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 ml-auto mr-auto mb-5">
            <div className="card p-4 rounded-plus bg-faded">
              <div className="subheader">
                <h1 className="subheader-title">
                  <i className="subheader-icon fal fa-shield-check"></i> Verify OTP
                </h1>
              </div>

              <Form onFinish={onSubmit} autoComplete="off" layout="vertical">
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp("^[0-9+]{0,6}$"),
                      message: "Please enter otp",
                    },
                  ]}
                >
                  <Input
                    onChange={(value) => handleChange("otp", value)}
                    placeholder="Enter OTP"
                    maxLength="6"
                  />
                </Form.Item>

                <div class="row no-gutters">
                  <div class="col-md-12 ml-auto text-right">
                    <Button className="mt-3" type="primary" htmlType="submit" loading={btnLoading}>
                      Verify
                    </Button>
                    <Button
                      className="ml-2 mt-3"
                      type="secondary"
                      htmlType="button"
                      onClick={() => navigate("/register")}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
