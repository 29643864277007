import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getUserType } from "../../utils/Helpers";

const ButtonUI = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={() => navigate(props.url)}
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-5 d-block l-h-n m-0 fw-500 text-white">
            {props.title}
            <small className="m-0 l-h-n">{props.subtitle}</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={{ fontSize: "6rem" }}
        ></i>
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const setSpinner = useOutletContext();

  useEffect(() => {
    setSpinner(false);
  }, [setSpinner])

  return (
    <div className="content">
      <div className="row">
        {getUserType() === "1" || getUserType() === "2" || getUserType() === "3" ? (
          <>
            <ButtonUI
              title="Previous Data"
              subtitle="Details"
              url="/previous-data"
              icon="fal fa-users"
              color="bg-primary-500"
              history={props.history}
            />
            <ButtonUI
              title="Members (Registered)"
              subtitle="Details"
              url="/members"
              icon="fal fa-users"
              color="bg-success-500"
              history={props.history}
            />
            <ButtonUI
              title="Receipts"
              subtitle="Details"
              url="/receipt-information"
              icon="fal fa-receipt"
              color="bg-warning-500"
              history={props.history}
            />
          </>
        ) : ""}
        <ButtonUI
          title="My Profile"
          subtitle="Details"
          url="/profile-details"
          icon="fal fa-user"
          color="bg-info-500"
          history={props.history}
        />
        <ButtonUI
          title="My Fees"
          subtitle="Details"
          url="/member-fees"
          icon="fal fa-receipt"
          color="bg-danger-500"
          history={props.history}
        />
      </div>
    </div>
  );
};

export default Dashboard;
