import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input } from "antd";

import PageHeader from "../common/PageHeader";
import ReceiptAmount from "./ReceiptAmount";
import { useOutletContext } from "react-router-dom";

const Receipt = () => {
  const setSpinner = useOutletContext();
  const [state, setState] = useState({
    mobile_numer: null,
  });
  const [showAmount, setShowAmount] = useState(false);

  useEffect(() => {
    setSpinner(false);
  }, []);

  const handleMobileNumberChange = (value) => {
    setState({ ...state, mobile_numer: value.target.value });

    const mobNo = value.target.value;
    if (mobNo.length === 10) {
      setShowAmount(true);
    } else {
      setShowAmount(false);
    }
  };

  const regExpNumber = /^\d+$/;

  return (
    <div id="content">
      <PageHeader
        pageTitle="Members"
        pageIcon={<i className="subheader-icon fal fa-receipt"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Receipt</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Mobile Number"
                        name="mobile_number"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(regExpNumber),
                            message: "Please enter member's mobile number!",
                          },
                        ]}
                      >
                        <Input onChange={(value) => handleMobileNumberChange(value)} maxLength="10" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                {showAmount && (
                  <ReceiptAmount
                    mobileNo={state.mobile_numer}
                    showAmount={setShowAmount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;